#mapid {
    height: calc(100vh - 55px);
}

.panel {
    position: fixed !important;
    z-index: 800;
    right: 0 !important;
    width: 220px !important;
    margin: 10px !important;
    background: rgba(255, 255, 255, 0.9) !important;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1) !important;
}

.panel.panel-info .panel-list {
    padding: 5px 20px;
}

.panel:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2) !important;
}

.panel.panel-info:hover {
    cursor: grab;
}

.panel.panel-info {
    margin-top: 240px !important;
}

.panel-left {
    position: fixed !important;
    z-index: 800;
    left: 0 !important;
    width: 100px !important;
    margin: 10px !important;
    /* background: rgba(255, 255, 255, 0.8) !important;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1) !important; */
}

.panel-left-frequency {
    padding: 0 6px;
}

.panel-left.panel-legend {
    bottom: 50px !important;
    line-height: 19px;
}

.panel-left.panel-legend i {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    float: left;
    opacity: 1;
}

.search-fragment-card {
    font-size: 1.2em;
    margin: 30px 10px;
}

.search-fragment-card:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2) !important;
}

.search-fragment-div {
    margin-bottom: 10px;
}

.search-fragment-div .highlight {
    color: #cc0000;
    text-decoration: bold;
}

.ui.label.search-fragment-header {
    font-size: 0.9em;
}
