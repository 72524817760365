.ui.menu {
  margin: 0 !important;
}

.modal-description {
  padding-bottom: 20px;
}

.center {
  position: absolute;
  z-index: 800;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.loading-circle {
  display: inline-block;
  width: 100px;
  height: 100px;
  margin: auto;
  border-radius: 50%;
  background: #2faee0;
  opacity: 0.8;
  -webkit-animation: loading-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
          animation: loading-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@-webkit-keyframes loading-circle {
  0%,
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
            animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(1800deg);
    -webkit-animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
            animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: rotateY(3600deg);
  }
}

@keyframes loading-circle {
  0%,
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
            animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(1800deg);
    -webkit-animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
            animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: rotateY(3600deg);
  }
}

#mapid {
    height: calc(100vh - 55px);
}

.panel {
    position: fixed !important;
    z-index: 800;
    right: 0 !important;
    width: 220px !important;
    margin: 10px !important;
    background: rgba(255, 255, 255, 0.9) !important;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1) !important;
}

.panel.panel-info .panel-list {
    padding: 5px 20px;
}

.panel:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2) !important;
}

.panel.panel-info:hover {
    cursor: -webkit-grab;
    cursor: grab;
}

.panel.panel-info {
    margin-top: 240px !important;
}

.panel-left {
    position: fixed !important;
    z-index: 800;
    left: 0 !important;
    width: 100px !important;
    margin: 10px !important;
    /* background: rgba(255, 255, 255, 0.8) !important;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1) !important; */
}

.panel-left-frequency {
    padding: 0 6px;
}

.panel-left.panel-legend {
    bottom: 50px !important;
    line-height: 19px;
}

.panel-left.panel-legend i {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    float: left;
    opacity: 1;
}

.search-fragment-card {
    font-size: 1.2em;
    margin: 30px 10px;
}

.search-fragment-card:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2) !important;
}

.search-fragment-div {
    margin-bottom: 10px;
}

.search-fragment-div .highlight {
    color: #cc0000;
    -webkit-text-decoration: bold;
            text-decoration: bold;
}

.ui.label.search-fragment-header {
    font-size: 0.9em;
}

html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

