.ui.menu {
  margin: 0 !important;
}

.modal-description {
  padding-bottom: 20px;
}

.center {
  position: absolute;
  z-index: 800;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loading-circle {
  display: inline-block;
  width: 100px;
  height: 100px;
  margin: auto;
  border-radius: 50%;
  background: #2faee0;
  opacity: 0.8;
  animation: loading-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@keyframes loading-circle {
  0%,
  100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: rotateY(3600deg);
  }
}
